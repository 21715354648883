import classNames from 'classnames'
import { useEffect, useState } from 'react'
import './App.scss'
import "./Home.scss"
import WpContent from './WpContent'

export default function Home() {
  const [logos, setLogos] = useState([])
  const [copy, setCopy] = useState('')
  return (
    <div className="home container mx-auto">
      <header>
        <WpContent id={682801}
            onLoad={res => {
              console.log(res)
                // setTitle(res.title)
                setLogos(res.acf.logos || [])
                setCopy(res.acf.body_copy)
                // setFormCopy(res.acf.form_copy)
              }}
          />
      </header>
      <div className='partners'>
        {copy && <div className='copy' dangerouslySetInnerHTML={{__html: copy}} />}
        <div className='letter'>
          <iframe id="letter" src="https://drive.google.com/file/d/17wjRjn1BGAUpRWdeZkDVZoub0jls5qvM/preview" width="100%"></iframe>

          {/* <iframe id="letter" src="https://bidenceasefirenow.com/letter.pdf" width="100%" > */}
          {/* </iframe> */}
        </div>
        <div className='logos'>
          {logos.map((logo, i) => {
            return (
              <div className='img' style={{ backgroundImage: `url(${logo.logo})` }} key={`l-${i}`}></div>
            )
          })}
        </div>
      </div>
    </div>
  );
}